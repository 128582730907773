import classNames from 'classnames';
import Image from 'components/image';
import React from 'react';
import Style from 'styles/components/comachicart/customers_voice.module.scss'

const CustomersVoice = () => {
  const imagePrefix = 'comachi_lp/top/';
  return (
    <section className={Style.customersVoice}>
      <h2 className={classNames(Style.catch)}>
        <span>お客様の声</span>
      </h2>
      <div className={classNames(Style.customersVoice__template)}>
        <div>
          <Image className={classNames(Style.customersVoice__img)} filename={imagePrefix + 'customer_pink.png'}/>
        </div>
        <div>
          <h4 className={Style.customersVoice__h4}>アクセサリー販売 A.Rさん</h4>
          <p>他のサービスでは必要な情報を自分で見つけ出すのに苦労しました。しかし、comachicartでは初めての打ち合わせから、私に合わせた説明をしてくれました。「こういう時にはこれを参照してください」と一通り説明してくれるのでその後も進めやすいです。 </p>
        </div>
      </div>
      <div className={classNames(Style.customersVoice__template)}>
        <div>
          <Image className={classNames(Style.customersVoice__img)} filename={imagePrefix + 'customer_red.png'}/>
        </div>
        <div>
          <h4 className={Style.customersVoice__h4}>冷凍魚卸売業者 N.Yさん</h4>
          <p>他社のサービスを利用してみたものの、サイトオープン後も満足できる結果には至らず、忙しい日常業務の中で、改善作業に1か月もの時間がかかっていました。 そこで、comachicartに切り替えたところ、担当者の方からこまめにとても優しく「何かお困りですか？どうですか？」と連絡頂いたこともあり、スピード感を持って進めることができました。特に印象的だったのは、画面共有を使ったサポートです。何かわからないことがあれば、その空気感を察してもう一度説明してくださいました。</p>
        </div>
      </div>
    </section>
  );
}

export default CustomersVoice;